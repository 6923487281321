<template>
  <div class="missing-dialog-content">
    <form>
      <div class="form-input-bloc">
        <h3>{{ $t('search.empty.name') }}</h3>
        <el-input
          v-model="missingForm.missing.title"
          :placeholder="$t('search.empty.name')"
          class="generic-border"
        />
      </div>

      <div class="form-input-bloc">
        <h3>{{ $t('search.empty.description') }}</h3>
        <el-input
          v-model="missingForm.missing.description"
          :autosize="{ minRows: 4, maxRows: 4 }"
          type="textarea"
          :placeholder="$t('search.empty.description_placeholder')"
        />
      </div>

      <div class="form-input-bloc">
        <h3>
          {{ $t('search.empty.url') }}
          <span>({{ $t('search.empty.optional') }})</span>
        </h3>
        <el-input
          v-model="missingForm.missing.url"
          :placeholder="$t('search.empty.url_placeholder')"
          class="generic-border"
        />
      </div>

      <button @click.prevent="sendReport()" class="generic-btn">
        {{ $t('search.empty.send') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  emits: ['close'],
  data() {
    return {
      missingForm: {
        type: 1,
        missing: {
          title: null,
          description: null,
          url: null,
        },
        message: this.$t('commons.error_submit'),
      },
    }
  },
  methods: {
    ...mapActions({
      errorMissingBottle: 'errorMissingBottle',
    }),
    async sendReport() {
      await this.errorMissingBottle(this.missingForm).then(() => {
        this.$emit('close', true)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.missing-dialog-content {
  overflow: hidden;

  form {
    display: flex;
    flex-direction: column;

    .form-input-bloc {
      h3 {
        color: white;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 21px;

        span {
          color: $grey40;
          font-size: 15px;
          font-weight: 500;
        }
      }

      textarea {
        color: #ffffff;
      }
    }

    button {
      width: 102px;
      margin: 0;
      margin: auto;
      margin-top: 34px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .missing-dialog-content {
    width: 99% !important;
  }
}
</style>
